import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import { useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import QAApi from "services/QAApi";
import { SearchFilter } from "services/QAApi/types";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useToaster from "utils/hooks/useToaster";

interface Props {
    searchFilter: SearchFilter;
  }

const PopoverQACardList : React.FC<Props> = props => {
    const toaster = useToaster();
    const [isLoading, setIsLoading] = useState(false);
    const handleVaskAlle = async () => {

        try {
            setIsLoading(true);
            const res = await QAApi.vaskAlleEndringsmeldinger(props.searchFilter.source, props.searchFilter.orderBy);
            setIsLoading(false);
            toaster.success('Suksess', `${res}`);
        } catch (err) {
            toaster.error('Feil! Noe gikk galt.', HttpErrorHelper.formatError(err));
        }
    };
    const getButtonText = () => {        
        let orderByText = 'nyeste';
        if (props.searchFilter.orderBy === "ascending") {
            orderByText = 'eldste';
        }
        let sourceText = 'fra alle kilder';
        if (props.searchFilter.source) {
            sourceText = `fra ${props.searchFilter.source}`;
        }
        return `Vask 50 ${orderByText} ${sourceText} på nytt`;
    } 
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <IconButton icon='redo' disabled={isLoading} onClick={handleVaskAlle} variant="outline-primary" style={{ width: '100%', border: '0px' }} >{getButtonText()}</IconButton>
            </Popover.Body>
        </Popover>
    );
    return (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <button style={{ border: 'none', background: 'transparent', padding: 0 }}>
                <IcebergIcon icon="ellipsis" color='#68778D' />
            </button>
        </OverlayTrigger>
    );
};

export default PopoverQACardList;