import React, { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup } from "react-bootstrap";

import cx from "classnames";

import CompareIcon from "../../../CompareIcon";

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetApi from "services/KjederegisteretAdminApi/EnhetApi";
import { LeverandorKundenummerDto } from "services/KjederegisteretAdminApi/EnhetApi/types";

import openInNewTab from "utils/functions/openInNewTab";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import { qaExistInCommaSeparatedString } from "pages/QA/components/QACardDetails/utils";

import Style from "../../index.module.css";
import useLocalStorage from "utils/hooks/useLocalStorage";

interface Props {
  values: string[];
  onChange: (values: string[]) => void;
  enhetId?: number;
  leverandorKundenummer?: LeverandorKundenummerDto[];
  changeRequest: {
    leverandorKundenummerHos: string;
    leverandorKundenummerHosTsKundeId: string;
    leverandorKundenummer: string;
  };
}

const KundenummerRow = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [improvedKundenummer, setImprovedKundenummer] = useState<string>(props.values.join(', '));
  const [compareStatus, setCompareStatus] = useState<string>(
    qaExistInCommaSeparatedString(improvedKundenummer, props.changeRequest.leverandorKundenummer)
  );
  const [otherConnectedEnheter, setOtherConnectedEnheter] = useState<LeverandorKundenummerDto[]>([]);
  const [isUserPreferenceExpandRow, setIsUserPreferenceExpandRow] = useLocalStorage<boolean>('userPreferenceKundenummerRowExpanded', false);

  const loadOtherConnectedEnheter = async () => {
    if (props.changeRequest.leverandorKundenummerHosTsKundeId && props.changeRequest.leverandorKundenummer) {
      const result = await EnhetApi.getLeverandorKundenummer(props.changeRequest.leverandorKundenummerHosTsKundeId, props.changeRequest.leverandorKundenummer)
      setOtherConnectedEnheter(result?.length > 0 ? result.filter(x => x.enhetId !== props.enhetId) : []);
    }
  }

  const transferKundeNr = (kundenr: string) => {
    // convert input to array
    const arr = parseInputToArray();

    // add input to array if it does not already exist
    if (arr.indexOf(kundenr) === -1) {
      arr.push(kundenr);
    }

    // update collection
    props.onChange(arr);
  };

  const handleKundeNrBlur = () => {
    const arr = parseInputToArray();
    props.onChange(arr);
  };

  const parseInputToArray = () => improvedKundenummer.split(',').map(o => o.trim()).filter(o => o);

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny' || otherConnectedEnheter?.length > 0) {
      setIsExpandRow(true);
    }

    if (isUserPreferenceExpandRow) {
      setIsExpandRow(true);
    }

  }, [compareStatus, otherConnectedEnheter]);

  useEffect(() => {
    setCompareStatus(
      qaExistInCommaSeparatedString(improvedKundenummer, props.changeRequest.leverandorKundenummer)
    );
  }, [improvedKundenummer, props.changeRequest.leverandorKundenummer]);

  useEffect(() => {
    loadOtherConnectedEnheter();
  }, [props.changeRequest.leverandorKundenummer])

  useEffect(() => {
    if (isMountedRef.current) {
      setImprovedKundenummer(props.values.join(', '));
    }
  }, [props.values]);

  const handleExpandRowClick = () => {
    setIsExpandRow(!isExpandRow);
    setIsUserPreferenceExpandRow(!isExpandRow);
  };

  return (
    <>
      <div
        className={cx(Style.changeRequestRowHeaderContainer, {
          [Style.isNotExpandedRowContainer]: !isExpandRow,
        })}
        onClick={handleExpandRowClick}
      >
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          <span className={Style.changeRequestRowHeaderText}>
            Kundenummer hos leverandør:{' '}
            <strong>{props.changeRequest.leverandorKundenummerHos}</strong>
          </span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm" className={Style.inputGroup}>
                  <Form.Control value={props.changeRequest.leverandorKundenummer || ''} readOnly />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={props.changeRequest.leverandorKundenummer} />
                  </InputGroup.Text>
                </InputGroup>
                {
                  otherConnectedEnheter?.length > 0 &&
                  <Form.Text>
                    <span style={{ color: 'red' }}>Andre enheter koblet til dette kundenummeret: </span><br />
                    {
                      otherConnectedEnheter.map(x =>
                        <div key={x.enhetId}>
                          <a href='#' onClick={(e) => openInNewTab(`/enheter/${x.enhetId}`, e)}>
                            {x.enhetNavn}
                          </a>
                        </div>
                      )
                    }
                  </Form.Text>
                }
              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              <CompareIcon
                compareStatus={compareStatus}
                onClick={() => transferKundeNr(props.changeRequest.leverandorKundenummer)}
              />
            </div>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm" className={Style.inputGroup}>
                  <Form.Control
                    className={Style.formControl}
                    type="text"
                    value={improvedKundenummer || ''}
                    onChange={e => setImprovedKundenummer(e.target.value)}
                    onBlur={handleKundeNrBlur} />
                </InputGroup>
                {props.leverandorKundenummer
                  ?.filter(x => x.tsKundeId !== props.changeRequest.leverandorKundenummerHosTsKundeId)
                  .length > 0 && <Form.Text>
                    Andre leverandører med kundeforhold til enhet: <br />
                    {
                      props.leverandorKundenummer.filter(x => x.tsKundeId !== props.changeRequest.leverandorKundenummerHosTsKundeId).map(x =>
                        <div key={x.id}>
                          {x.mottakerNavn}: {x.kundenummer}
                        </div>
                      )
                    }
                  </Form.Text>}
                {props.leverandorKundenummer
                  ?.filter(x => x.tsKundeId !== props.changeRequest.leverandorKundenummerHosTsKundeId)
                  .length === 0 && <Form.Text>
                    Ingen andre leverandører med kundeforhold til enhet
                  </Form.Text>}
              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default KundenummerRow;