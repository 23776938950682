import { format } from "date-fns";
import { be } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AlkoholBevillingerApi from "services/KjederegisteretAdminApi/AlkoholBevillingerApi";
import { AlkoholBevilling } from "services/KjederegisteretAdminApi/AlkoholBevillingerApi/types";
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";

interface Props {
    enhet: EnhetViewModel;
    onUpdated: () => void;
}

const Bevillinger = (props: Props) => {

    const kundenummer = props.enhet.leverandorKundenummer.filter(o => o.mottakerNavn == "HELSEDIREKTORATET");

    const [bevillinger, setBevillinger] = useState<AlkoholBevilling[]>([]);

    const loadBevilling = async (kundenr: string[]) => {
        const result = await AlkoholBevillingerApi.getByBevillingNr(kundenr);
        setBevillinger(result);
    }

    useEffect(() => {
        if (kundenummer && kundenummer.length > 0) {
            loadBevilling(kundenummer.map(o => o.kundenummer));
        }
    }, [kundenummer.length]);

    return (
        <div>
            {kundenummer.length === 0 && <div>
                Ingen alkoholbevillinger registrert på enhet
            </div>}
            {kundenummer.length > 0 && <div>
                Alkohol bevillingsnr hos Helsedirektoratet
                {bevillinger?.length == 0 && <div>
                    Ingen bevilling registrert på enhet
                </div>}
                {bevillinger?.length > 0  &&
                    <Table className="mt-3">
                        <thead>
                            <th>Bevillingsnummer</th>
                            <th>TillatelsesType</th>
                            <th>Varegrupper</th>
                            <th>Periode</th>
                        </thead>
                        <tbody>
                            {bevillinger.map(bevilling => <tr>
                                <td>{bevilling.bevillingsnummer}</td>
                                <td>{bevilling.tillatelsestype}</td>
                                <td>{bevilling.varegrupper}</td>
                                <td>{format(bevilling.fra, "dd.MM.yyyy")} - {bevilling.til ? format(bevilling.til, "dd.MM.yyyy") : ""}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                }
            </div>}

        </div>
    )
}

export default Bevillinger;