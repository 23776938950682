import axios from 'axios';

import { AlkoholBevilling } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/alkoholbevillinger';

const AlkoholBevillingerApi = {
    getByBevillingNr: async (bevillingsnummer: string[]): Promise<AlkoholBevilling[]> => {
        const response = await axios.get(`${baseUrl}/${bevillingsnummer.join(',')}`);
        return response.data;
    }
}

export default AlkoholBevillingerApi;
